import { h } from 'hyperapp';

import { validate, logMetOptions, onFailOptions, RULES_EXEC_DONE } from '../../modules/rule';
import { untagify, validateEditor, validateButton, toKeyValue, dateToString } from '../../modules/utils';

import { Loading } from './loading';
import { CheckBox, Dropdown, Divider, Modal, RequiredField, DateTimeField, TagsInput, CreateUpdateControl } from './common';
import { EventSelector, EventRuleIdSelector } from './event-selector';
import { RuleDelay } from './rule-delay';
import { Criterias } from './criteria-editor';
import { Actions } from './action-editor';
import { RulePlayEditor } from './rule-play-editor';
import { RuleButtons, RestoreButton } from './rule-buttons';
import { RulePreview } from './rule-preview';
import { ActionEventFieldsEditor } from './action-event-fields';

import '../../css/rule-editor.css'

const getPlayResult = (playResult) => {
  const { rule, event, context, unmet, error, duration } = playResult;
  const result = 'rule executed ' + (error ? 'with error' : (unmet ? 'with unmet' : 'successfuly'));
  var res = { result }
  if (unmet) {
    res.unmet = unmet;
  }
  if (error) {
    res.error = error;
  }
  res = { ...res, error, event: event.topic, rule: { title: rule.title, id: rule.id }, context, duration };
  return JSON.stringify(res, null, 2);
}

export const RuleEditor = ({ state, actions, id, isAdmin }) => {

  const err = state.active ? validate(state, state) : (!state.title && 'Please add rule title');

  const getData = () => {
    actions.getRule(id);
  }

  const setValue = (prop, value, keep) => {
    const newState = { [prop]: value }
    if (!keep) {
      newState.modified = true;
    }
    actions.setState(newState);
  }

  const save = (e, exit, empty) => {
    if (state.active) validateEditor(e.target.closest('.rule-editor'));
    if (err) {
      actions.setState({ error: err });
    } else {
      actions.saveRule({ exit, empty });
    }
  }

  const saveAndExit = e => {
    save(e, true)
  }

  const saveAndNew = e => {
    save(e, false, true)
  }

  const validatePlay = (el) => {
    if (state.modified) {
      el.classList.add('disabled');
    } else {
      validateButton(el, validate(state, state))
    }
  }

  const validatePlayData = (el) => {
    // validateEditor(el, '.modal');
  }
  const openPlayModal = () => {
    actions.setState({ playModal: true, playData: {}, playResult: '', playError: '' })
  }
  const closePlayModal = () => {
    actions.setState({ playModal: false, playData: null, playResult: '', playError: '' })
  }

  const closeEventFieldsModal = () => {
    actions.setState({ eventFields: null })
  }

  return (
    <div class="rule-editor" oncreate={getData} ondestroy={() => actions.getRule()} >
      {state.loaded ? (<div>
        <div class={'box' + (state.showDetails ? ' show-details' : '')}>
          <RuleTitle title={state.title} active={state.active} setValue={setValue} showDetails={state.showDetails} />
          <RuleDetails {...state} setValue={setValue} />
          <Divider />
          <div class="field is-grouped datasource">
            <div class="control">
              <EventSelector value={state.event} events={state.events} onSelect={actions.selectEvent} />
            </div>
            {state.event == RULES_EXEC_DONE && (
              <div class="control">
                <EventRuleIdSelector value={state.ruleId} rules={state.ruleIds} onSelect={actions.selectChainRule} />
              </div>
            )}
            <div class="control">
              <RuleDelay state={state} actions={actions} />
            </div>
          </div>
          {state.event && (<div>
            <Criterias state={state} actions={actions} />
            <Divider text="then" />
            <Actions state={state} actions={actions} hasUnmet={true} />
          </div>)}
        </div>
        {!state.archive ? (<RuleButtons clear={() => actions.getRule()} canPlay={!!id} openPlay={openPlayModal} playDisabled={state.modified || !!err} validatePlay={validatePlay} save={save} saveAndExit={saveAndExit} saveAndNew={saveAndNew} saveDisabled={!state.modified || !!err} preview={state.preview} togglePreview={actions.togglePreview} />) :
          (<RestoreButton restore={() => actions.restoreRule()} preview={state.preview} togglePreview={actions.togglePreview} />)}
        {state.error ? (<div class="notification is-danger">{state.error}</div>) : null}
        {isAdmin && (<div>
          <div class="is-small is-boxed">
            <RulePreview state={state} actions={actions} />
          </div>
        </div>)}
        {id && state.event && (state.playData || state.playResult) && (
          <Modal open={state.playModal} title={`Play ${state.title}`} confirmText={!state.playResult ? "Run" : "Rerun"} onConfirm={actions.play} onCancel={closePlayModal} onValidate={validatePlayData}
            footer={(<div style={{ position: 'absolute', right: '20px' }}>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label" for="is_dryRun">Dry Run</label>
                </div>
                <div class="field-body">
                  <CheckBox id="is_dryRun" active={state.dryRun} toggle={value => setValue('dryRun', !!value)} />
                </div>
              </div>
            </div>)}>
            {state.playResult ? (<pre><code>{getPlayResult(state.playResult)}</code></pre>)
              : (<RulePlayEditor state={state} actions={actions} />)}
          </Modal>)}
        {state.eventFields && (<Modal open={state.eventFields.open} title={`${state.eventFields.event.id} fields`} cancelText="OK" onCancel={closeEventFieldsModal} >
          <ActionEventFieldsEditor {...state.eventFields} criterias={state.criterias} setEventFields={actions.setEventFields} />
        </Modal>)}
      </div>
      ) : (<Loading />)}
    </div>
  )
}

const RuleTitle = ({ title, active, setValue, showDetails }) => {
  return (<div class="rule-heading">
    <div class="columns">
      <div class="column rule-title">
        <RequiredField placeholder="Title" value={title} onchange={({ target }) => setValue('title', target.value)} className="is-expanded" />
        <p class="title is-4">{title}</p>
      </div>
      <div class="column is-one-fifth">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" for="is_active">Active</label>
          </div>
          <div class="field-body">
            <CheckBox id="is_active" active={active} toggle={value => setValue('active', !!value)} />
          </div>
        </div>
      </div>
    </div>
    {showDetails && (<Divider />)}
    <button class="button is-rounded is-white rule-toggle" title={(showDetails ? 'Hide' : 'Show') + ' details'} onclick={() => setValue('showDetails', !showDetails, true)}>
      <span class="icon is-small">
        <i class={`fas fa-${showDetails ? 'minus-square' : 'plus-square'}`}></i>
      </span>
    </button>
  </div>)
}

const RuleDetails = ({ desc, tags, onUnmet, onMet, onFail, group, validFrom, validTo, setValue, created, createdBy, updated, updatedBy, showDetails, showCreateUpdate, noCriterias, chainable }) => {
  return (<div class="rule-details">
    <div class="rule-details-main">
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Description</label>
            <div class="control">
              <textarea class="textarea" rows="3" placeholder="Description" value={desc} onchange={({ target: { value } }) => setValue('desc', value)}></textarea>
            </div>
          </div>
        </div>
        <div class="column is-two-fifths">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Validity</label>
            </div>
            <div class="field-body">
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">From</label>
            </div>
            <div class="field-body">
              <DateTimeField placeholder="Valid From" value={dateToString(validFrom)} onchange={({ target }) => setValue('validFrom', target.value)} />
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">To</label>
            </div>
            <div class="field-body">
              <DateTimeField placeholder="Valid To" value={dateToString(validTo)} onchange={({ target }) => setValue('validTo', target.value)} />
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-two-fifths">
          <label class="label">Group</label>
          <div class="control">
            <Dropdown value={group} onchange={value => setValue('group', value)} items={[]} valueField="key" displayField="value" orderField="index" className="is-expanded" disabled={true} />
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Tags</label>
            <div class="control">
              <TagsInput placeholder="Tags" value={untagify(tags)} onchange={tags => setValue('tags', tags)} className="is-expanded" />
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-one-fifths">
          <label class="label">Log Unmet</label>
          <div class="control">
            <Dropdown value={onUnmet} onchange={value => setValue('onUnmet', parseInt(value))} items={toKeyValue(logMetOptions)} valueField="key" displayField="value" orderField="index" className="is-expanded" />
          </div>
        </div>
        <div class="column is-one-fifths">
          <label class="label">Log Met</label>
          <div class="control">
            <Dropdown value={onMet} onchange={value => setValue('onMet', parseInt(value))} items={toKeyValue(logMetOptions)} valueField="key" displayField="value" orderField="index" className="is-expanded" />
          </div>
        </div>
        <div class="column is-one-fifths">
          <label class="label">On Failure</label>
          <div class="control">
            <Dropdown value={onFail} onchange={value => setValue('onFail', parseInt(value))} items={toKeyValue(onFailOptions)} valueField="key" displayField="value" orderField="index" className="is-expanded" />
          </div>
        </div>
        <div class="column is-one-fifth">
          <label class="label" for="is_noCriterias">No Criterias</label>
          <div class="control">
            <CheckBox id="is_noCriterias" active={noCriterias} toggle={value => setValue('noCriterias', !!value)} />
          </div>
        </div>
        <div class="column is-one-fifth">
          <label class="label" for="is_chanable">Chainable</label>
          <div class="control">
            <CheckBox id="is_chanable" active={chainable} toggle={value => setValue('chainable', !!value)} />
          </div>
        </div>
      </div>
      {showDetails && (<button class="button is-rounded is-white rule-toggle" title={'Show ' + (showCreateUpdate ? 'less' : 'more')} onclick={() => setValue('showCreateUpdate', !showCreateUpdate, true)}>
        <span class="icon is-small">
          <i class={`fas fa-${showCreateUpdate ? 'minus-square' : 'plus-square'}`}></i>
        </span>
      </button>)}
    </div>
    {showCreateUpdate && (<Divider />)}
    {showCreateUpdate && (<CreateUpdateControl created={created} createdBy={createdBy} updated={updated} updatedBy={updatedBy} />)}
  </div>)
}
